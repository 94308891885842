@import '@/styles/_shared';

@media print {
  .footer {
    display: none !important;
  }
}

.footer {
  background-color: #003366;
  color: $alabaster2;
  padding-top: 10px;
  padding-bottom: 50px;

  @include breakpoint-up('sm') {
    padding-bottom: 0px;
  }
}

.footerLogoAndSocials {
  width: 100%;
  flex-basis: 100%;
  order: 1;

  @include breakpoint-up('sm') {
    display: block;
    width: 50%;
    flex-basis: 50%;
    order: 0;
  }

  @include breakpoint-up('md') {
    width: 40%;
    flex-basis: 40%;
  }

  @include breakpoint-up('lg') {
    width: 25%;
    flex-basis: 25%;
  }
}

.footerLogo {
  display: none;
  margin-bottom: 25px;

  @include breakpoint-up('sm') {
    display: block;
  }

  width: 216px;

  @include breakpoint-up('md') {
    width: 241px;
  }

  @include breakpoint-up('lg') {
    width: 170px;
  }

  @include breakpoint-up('xl') {
    width: 241px;
  }
}

.footerSocials {
  display: flex;
  list-style: none;
  margin: 0;
  padding-top: 40px;

  @include breakpoint-up('sm') {
    padding-top: 0;
  }
}

.socialLinkItem {
  width: 20%;
  text-align: center;

  @include breakpoint-up('sm') {
    width: auto;
  }

  svg {
    width: 21px;
    height: 21px;

    @include breakpoint-up('sm') {
      margin-right: 20px;
    }

    @include breakpoint-up('xl') {
      margin-right: 30px;
    }

    * {
      fill: $alabaster2;
    }
  }
}

.footerSection {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
  font-size: 14px;

  &:last-child {
    border: none;
  }
}

.footerBlock {
  width: 100%;
  color: black;

  h2 {
    margin-bottom: 25px;

    @include breakpoint-up('sm') {
      margin-bottom: 0;
    }
  }

  &.footerEmailContent {
    @include breakpoint-up('md') {
      width: 100%;
      padding-right: 25px;
    }

    @include breakpoint-up('lg') {
      padding: 0;
    }
  }

  &.footerEmailSignup {
    @include breakpoint-up('md') {
      width: 100%;
      padding: 0;
    }

    @include breakpoint-up('lg') {
      padding-left: 20px;
      margin: auto;
      width: 50%;
    }
  }

  &.footerEmailFormContainer {
    @include breakpoint-up('md') {
      width: 40%;
    }

    @include breakpoint-up('lg') {
      width: 50%;
    }
  }
}

.footerEmailSignup {
  text-align: center;
  padding: 20px;
  background-color: #bdccd4;
  margin-top: 25px;

  @include breakpoint-up('sm') {
    padding: 50px;
  }

  @include breakpoint-up('md') {
    padding: 60px;
    text-align: center;
  }

  .footerEmailSignupForm {
    padding: 0;

    @include breakpoint-up('md') {
      padding: 25px;
    }
  }
}

.footerEmailHeading {
  margin: 0 auto;

  @include breakpoint-up('sm') {
    margin: 0 auto 0 0;
  }
}

.footerEmailDescription {
  font-size: 14px;
  margin-bottom: 25px;
  display: none;

  @include breakpoint-up('sm') {
    display: block;
    max-width: 750px;
    margin: 0 auto;
  }

  @include breakpoint-up('md') {
    font-size: 16px;
  }
}


.footerNavigation {
  padding: 50px 0 40px;

  @include breakpoint-up('sm') {
    padding: 60px 0;
  }

  @include breakpoint-up('lg') {
    padding: 100px 0;
  }
}

.footerNavigationList {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  flex: 1;

  h2 {
    font-family: $bebas;
    letter-spacing: 0.02em;
    padding-bottom: 10px;
  }
}

.footerNavigationListItem {
  width: 100%;
  border-bottom: 2px solid rgba(255, 253, 252, 0.25);

  @include breakpoint-up('lg') {
    width: 20%;
    padding: 0 20px 0 0;
    border: none;
  }

  &:last-child {
    padding-right: 0;

    @include breakpoint-up('md') {
      border: none;
    }
  }
}

.footerNavigationListItemButton {
  text-align: left;
  padding: 12px 8px;
  width: 100%;
  min-height: 44px;
  background: none;
  border: none;
  color: $alabaster2;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 4px 0;

  @include breakpoint-up('lg') {
    cursor: auto;
    padding: 8px;
  }

  &:focus-visible {
    outline: 2px solid $alabaster2;
    outline-offset: 2px;
  }

  svg {
    position: absolute;
    width: 18px;
    height: 18px;
    right: 8px;
  }

  &.open {
    &::after {
      opacity: 0;
    }
  }

  h2 {
    color: #FFFFFF;
    font-weight: 500;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  }
}

.footerMenuItems {
  list-style: none;
  padding: 0 8px;

  li {
    margin: 8px 0;
  }
}

.footerMenuItem {
  display: block;
  padding: 12px 8px;
  min-height: 44px;
  text-decoration: none;
  color: $alabaster2;
  font-size: 16px;
  transition: background-color 0.2s;
  font-weight: 500;

  @include breakpoint-up('lg') {
    font-size: 16px;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    color: $salmon;
  }

  &:focus-visible {
    outline: 2px solid $alabaster2;
    outline-offset: 2px;
  }
}

.footerMenuItemText {
  display: block;
  padding: 4px 0;
  color: #FFFFFF;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
}

.footerBottom {
  padding: 25px 0 35px;

  @include breakpoint-up('sm') {
    padding: 20px 0;
  }

  @include breakpoint-up('md') {
    padding: 25px 0;
  }

  @include breakpoint-up('lg') {
    padding: 40px 0;
  }
}

.copyRight {
  text-align: center;
  width: 100%;
  margin-bottom: 15px;

  @include breakpoint-up('md') {
    text-align: left;
    width: auto;
    flex: 1;
    margin: 0;
  }

  @include breakpoint-down('md') {
    font-size: 12px;
    line-height: 140%;
  }

  color: #FFFFFF;
  font-weight: 500;
}

.termsLinksContainer {
  display: flex;
  align-items: center;
  width: 100%;

  @include breakpoint-up('md') {
    width: auto;
  }
}

.termsLinks {
  display: flex;
  justify-content: center;
  width: 100%;
  list-style: none;
  font-size: 14px;

  li {
    border-right: 1px solid $alabaster2;
    padding-right: 20px;
    margin-right: 20px;

    @include breakpoint-down('md') {
      font-size: 12px;
      line-height: 1;
    }

    &:last-child {
      padding-right: 0;
      margin-right: 0;
      border-right: none;
    }
  }

  a {
    font-size: 12px;
    color: $alabaster2;
    text-decoration: none;

    @include breakpoint-up('lg') {
      font-size: 14px;
    }

    &:hover {
      color: $salmon;
      text-decoration: underline;
    }
  }
}
